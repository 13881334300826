import { useState, useEffect } from 'react';
import { XCricle } from '../../../assets/images';
import { PeerOptions } from '../../../hooks/usePeers';
import { Paginator } from '../../../components/common/paginator';

const FilterBar: React.FC<{
  count?: number;
  setFilters: React.Dispatch<React.SetStateAction<PeerOptions>>;
  filters: PeerOptions;
}> = ({ count, setFilters, filters }): JSX.Element => {
  return (
    <div className="w-full border-b gray-border-line flex justify-between items-center bg-gray-background-light px-7 h-10 ">
      <div className="w-1/2">
        <Paginator
          count={count || 0}
          limit={filters.limit || 25}
          currentPage={filters.page || 1}
          handlePageChange={(newPage: number) => {
            setFilters((prevFilters) => ({ ...prevFilters, page: newPage }));
          }}
        />
      </div>
      <div className="w-1/4">
        <div className="w-full h-8">
          <SearchBar initialSearchValue={filters.search} setFilters={setFilters} />
        </div>
      </div>
    </div>
  );
};

const SearchBar: React.FC<{
  initialSearchValue?: string;
  setFilters: React.Dispatch<React.SetStateAction<PeerOptions>>;
}> = ({ initialSearchValue, setFilters }) => {
  const [searchInput, setSearchInput] = useState(initialSearchValue || '');
  let debounceTimer: string | number | ReturnType<typeof setTimeout> | undefined;

  const onSearchChange = (newSearchValue: string) => {
    setFilters((prevFilters) => ({ ...prevFilters, search: newSearchValue, page: 1 }));
  };

  useEffect(() => {
    setSearchInput(initialSearchValue || '');
  }, [initialSearchValue]);

  useEffect(() => {
    if (searchInput === initialSearchValue) {
      return;
    }
    // Clear the previous timer and set a new one
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => {
      onSearchChange(searchInput);
    }, 500);

    // Clear the timer on unmount
    return () => {
      clearTimeout(debounceTimer);
    };
  }, [searchInput, onSearchChange]);

  const handleSearchChange = (event: { target: { value: React.SetStateAction<string> } }) => {
    setSearchInput(event.target.value);
  };

  return (
    <div className="w-full bg-white rounded-full h-full flex ">
      <input
        type="text"
        placeholder="Search..."
        value={searchInput}
        className=" text-center focus:outline-none w-5/6 rounded-l-full h-full"
        onChange={handleSearchChange}
      />
      <div
        className="w-1/6 flex items-center justify-center rounded-r-full h-full pr-2"
        onClick={() => {
          setSearchInput('');
        }}
      >
        {searchInput.length > 0 && <img src={XCricle} alt="closeButton"></img>}
      </div>
    </div>
  );
};

export default FilterBar;
